import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../style/controlPlan.css";
import axios from "axios";
import ProcessProductAuditView from "./ProcessProductAuditView";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Wrapper } from "../../components/Control/Flexbox";
import { useQuery } from "../../components/Control/useQuery";

export default function CreateProcessProductAudit(props) {
  const { partId, id } = useParams();
  const [data, setData] = useState("");
  const history = useHistory();
  const query = useQuery();
  let isView = query.get("isView") === "false" ? false : true;
  const checklist_id = query.get("checklist_id");
  const monthYear = query.get("monthYear");
  const processDate = query.get("date");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1. Try to fetch using checklist_id if available
        if (checklist_id) {
          try {
            const res = await axios.get(
              `/api/getProcessProductAuditById?part_id=${partId}&checklist_id=${checklist_id}&monthYear=${monthYear}`,
            );
            if (res.data) {
              setData(res.data);
              return; // Stop further execution if data is found
            }
          } catch (err) {
            console.error("Error fetching with checklist_id:", err);
          }
        }

        // 2. If id is missing and first fetch did not return data, try fetching control plan part data
        if (!id) {
          try {
            const res = await axios.get(`/api/get/controlPlanPart/${partId}`);
            if (res.data) {
              // Optionally delete _id from the response if needed
              const modifiedData = { ...res.data };
              delete modifiedData._id;
              setData(modifiedData);
              return; // Stop further execution if data is found
            }
          } catch (err) {
            console.error("Error fetching control plan part:", err);
          }
        }

        // 3. Lastly, if only id is present (and checklist_id is missing or did not return data), fetch using id
        if (id) {
          try {
            const res = await axios.get(
              `/api/getProcessProductAuditById/${id}`,
            );
            if (res.data) {
              setData(res.data);
            }
          } catch (err) {
            console.error("Error fetching with id:", err);
          }
        }
      } catch (err) {
        console.error("Unexpected error fetching data:", err);
      }
    };

    fetchData();
  }, [id, partId, checklist_id, monthYear]);

  const handleSubmit = () => {
    data.date = processDate || new Date();
    if (id) {
      axios
        .put(`/api/updateProcessProductAudit/${data._id}`, data)
        .then((res) => {
          window.alert("successfully updated");
          history.goBack();
        })
        .catch((err) => console.log(err));
    } else {
      data.part_id = partId;
      data.monthYear = monthYear;
      if (checklist_id) data.checklist_id = checklist_id;
      delete data._id;
      axios
        .post(`/api/create/processProductAudit`, data)
        .then((res) => {
          if (res.data?.status === "failed") return alert(res.data.msg);
          window.alert("successfully created");
          history.goBack();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", justifyContent: "space-between" }}
      >
        PROCESS/PRODUCT AUDIT REPORT
        {!isView && (
          <Wrapper>
            <div>
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUpload />}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </Wrapper>
            </div>
          </Wrapper>
        )}
      </div>
      <div>{data && <ProcessProductAuditView Data={data} />}</div>
    </>
  );
}
