import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";

import TableScroll from "../../components/TableScroll";
import styled from "styled-components";
import TableReadEditCell from "../../components/TableReadEditCell";
import formatDate from "../../components/Control/formatDate";
import { useQuery } from "../../components/Control/useQuery";
import readableTime from "../../components/readableTime";
import { MenuItem, Select } from "@material-ui/core";
import { useAuth } from "../../context/GlobalContext";
const Table = styled.table`
  width: 98%;
  margin: auto;
  margin: 1rem;
  th {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    border: 1px solid black;
    padding: 0.4rem;
    p {
      margin: 0;
    }
  }

  td {
    font-size: 12px;
    border: 1px solid black;
    padding: 0.3rem;
    p {
      margin: 0;
    }
  }
  span {
    font-weight: 500;
  }

  .input {
    outline: none;
    width: 100%;
    border: 2px solid #f9e0e0;
    width: 100px;
    height: 50px;
    text-align: center;
  }
  .text-center th,
  td {
    text-align: center;
  }
`;

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const CreateEditInspectionReport = ({
  processes,
  pirReportData,
  machineList,
  ncrLog,
  setNcrLogs,
}) => {
  const classes = dropdownStyles();
  const { state } = useAuth();
  const [render, setRender] = useState(false);
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const [autocompletevalue, setAutoCompleteValue] = useState([]);
  const handleResult = (pirIndex, checkDataIndex) => {
    const isNotOk = pirReportData.pirs[pirIndex][
      `check_${checkDataIndex}_colors`
    ].find((item) => item === "#f7aeae");
    const isOk = pirReportData.pirs[pirIndex][
      `check_${checkDataIndex}_colors`
    ].find((item) => item === "#a4d1a4" || item === "#fbfbac");

    // pirReportData.pirs[tabValue].pir_list[pirIndex].result[checkDataIndex] =
    //   (isNotOk && "not ok") || (isOk && "ok") || "";
    setRender((prev) => !prev);
  };

  const handleChangeSetupReport = (e, pirIndex, arrIndex, checkDataIndex) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    pirReportData.pirs[pirIndex][`check_${checkDataIndex}_values`][arrIndex] =
      value;

    if (e.target.name === "form_control") {
      pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][arrIndex] =
        (value === "ok" && "#a4d1a4") ||
        (value === "not ok" && "#f7aeae") ||
        "";
    }

    // time auto pick
    let timeIndex = Math.floor(arrIndex / 2);
    pirReportData.times[timeIndex] = new Date();

    if (!pirReportData.start_time[timeIndex]) {
      const ans = window.confirm("Are you sure you want to start");
      if (!ans) return;
      pirReportData.start_time[timeIndex] = pirReportData.times[timeIndex];
    }

    ncrLog?.[arrIndex] &&
      (ncrLog[arrIndex].time = pirReportData.times[timeIndex]);
    // console.log(pirReportData.times[timeIndex], timeIndex);

    let mean = pirReportData.pirs[pirIndex].mean;
    let utolerance = pirReportData.pirs[pirIndex].utolerance;
    let ltolerance = pirReportData.pirs[pirIndex].ltolerance;
    let old_utl = pirReportData.pirs[pirIndex].utl;
    let old_ltl = pirReportData.pirs[pirIndex].ltl;
    let check4_symbol = pirReportData.pirs[pirIndex].check4_symbol;
    let checkbox4_num = pirReportData.pirs[pirIndex].checkbox4_num;
    let utl;
    let ltl;
    let uv;
    let lv;

    //max
    if (check4_symbol && checkbox4_num) {
      if (check4_symbol === "<=") {
        if (value <= checkbox4_num) {
          pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
            arrIndex
          ] = "#a4d1a4";
        } else {
          pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
            arrIndex
          ] = "#f7aeae";
        }
      }
      //min
      if (check4_symbol === ">=") {
        if (value >= checkbox4_num) {
          pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
            arrIndex
          ] = "#a4d1a4";
        } else {
          pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
            arrIndex
          ] = "#f7aeae";
        }
      }
    } else {
      if (old_utl || old_ltl) {
        utl = parseFloat(old_utl);
        ltl = parseFloat(old_ltl);
        const mean = (utl + ltl) / 2;
        uv = (mean + utl) / 2;
        lv = (mean + ltl) / 2;
      } else {
        utl = parseFloat(mean) + parseFloat(utolerance);
        ltl = parseFloat(mean) - parseFloat(ltolerance);

        uv = parseFloat(mean) + parseFloat(utolerance) / 2;
        lv = parseFloat(mean) - parseFloat(ltolerance) / 2;
      }
      if (value > utl || value < ltl) {
        pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
          arrIndex
        ] = "#f7aeae";
      } else if ((uv < value && value <= utl) || (lv > value && value >= ltl)) {
        pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
          arrIndex
        ] = "#fbfbac";
      } else if (value >= lv && value <= uv) {
        pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
          arrIndex
        ] = "#a4d1a4";
      }
    }

    if (e.target.className === "input" && !value) {
      pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][arrIndex] =
        "";
      pirReportData.times[timeIndex] = null;
    }
    handleResult(pirIndex, checkDataIndex);
  };

  function handlingDisabledOfObsBasedOnTime(id, time, updatedTime) {
    if (state.user.userType === 2) {
      return false;
    }
    if (pirReportData?.is_admin_request === "rejected") {
      return false;
    }
    if (!id) {
      return false;
    }
    if (!time) {
      return false;
    }
    return new Date(updatedTime) > new Date(time) ? true : false;
  }

  const handleObeservatonInputs = (
    checkType,
    pirIndex,
    arrIndex,
    checkDataIndex,
  ) => {
    if (checkType === "check3") {
      return (
        <TableReadEditCell
          name="observation"
          type="text"
          value={
            pirReportData.pirs[pirIndex][`check_${checkDataIndex}_values`][
              arrIndex
            ] || ""
          }
          style={{
            backgroundColor:
              pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
                arrIndex
              ],
          }}
          index={pirIndex}
          isView={isView}
          handleChange={(e, pirIndex) => {
            handleChangeSetupReport(e, pirIndex, arrIndex, checkDataIndex);
          }}
          disabled={handlingDisabledOfObsBasedOnTime(
            pirReportData?._id,
            pirReportData?.times?.[Math.floor(arrIndex / 2)],
            pirReportData?.updatedAt,
          )}
        />
      );
    }
    if (
      checkType === "check1" ||
      checkType === "check2" ||
      checkType === "check4"
    ) {
      return (
        <TableReadEditCell
          name="observation"
          type="number"
          value={
            pirReportData.pirs[pirIndex][`check_${checkDataIndex}_values`][
              arrIndex
            ] || ""
          }
          style={{
            backgroundColor:
              pirReportData.pirs[pirIndex][`check_${checkDataIndex}_colors`][
                arrIndex
              ],
          }}
          index={pirIndex}
          isView={isView}
          handleChange={(e, pirIndex) => {
            handleChangeSetupReport(e, pirIndex, arrIndex, checkDataIndex);
          }}
          // disbaled={

          // }
          disabled={handlingDisabledOfObsBasedOnTime(
            pirReportData?._id,
            pirReportData?.times?.[Math.floor(arrIndex / 2)],
            pirReportData?.updatedAt,
          )}
        />
      );
    }
  };

  const createNcrlogs = (e, index) => {
    if (e.target.value === "NC") {
      ncrLog[index] = {
        s_no: index + 1,
        time: pirReportData?.times?.[Math.floor(index / 2)] || null,
        item_code: pirReportData?.partNo || pirReportData.part_id.part_number,
      };
    } else {
      ncrLog[index] = null;
    }
  };

  const reusabelSelect = (index) => {
    return (
      <>
        {isView
          ? index % 2 === 0 && (
              <td
                colSpan={2}
                style={{
                  background: isView
                    ? (pirReportData?.result?.[index] === "OK" && "#37b24d") ||
                      (pirReportData?.result?.[index] === "NC" && "#f03e3e")
                    : "inherit",
                }}
              >
                {pirReportData?.result?.[index]}
              </td>
            )
          : index % 2 === 0 && (
              <td colSpan={2}>
                <Select
                  name="form_control"
                  variant="outlined"
                  style={{
                    width: "150px",
                    backgroundColor:
                      (pirReportData?.result?.[index] === "OK" && "#37b24d") ||
                      (pirReportData?.result?.[index] === "NC" && "#f03e3e"),
                  }}
                  value={pirReportData?.result?.[index] || ""}
                  onChange={(e) => {
                    createNcrlogs(e, index);
                    pirReportData.result[index] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem>None</MenuItem>
                  <MenuItem value="OK">OK</MenuItem>
                  <MenuItem value="NC">NC</MenuItem>
                </Select>
              </td>
            )}
      </>
    );
  };
  return (
    <main
      style={{
        paddingBottom: "4rem",
      }}
    >
      <TableScroll>
        <Table
          cellSpacing="0"
          style={{ width: "100%" }}
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="8, 15, 30, 20, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12"
        >
          <thead>
            <tr data-height="100">
              <th
                colSpan={14}
                rowSpan="4"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                  color: "#343a40",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.CompanyLogo} alt={textInputs.companyName} />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                FORMAT NO:
              </th>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                F QAD 73
              </th>
            </tr>

            <tr>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                ISSUE DATE:
              </th>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                01/07/2022
              </th>
            </tr>
            <tr>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                Revision No:
              </th>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                00
              </th>
            </tr>
            <tr>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                Revision Date:
              </th>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                00
              </th>
            </tr>
          </thead>

          <thead>
            <tr>
              <td
                colSpan={18}
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#212529",
                  color: "white",
                  border: "1px solid black",
                  textTransform: "uppercase",
                  color: "#f8f9fa",
                }}
                data-fill-color="FF000000"
              >
                INPROCESS INSPECTION REPORT
              </td>
            </tr>
            <tr>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={4}
              >
                M/C. NO.:{" "}
                {isView ? (
                  pirReportData.mc_no
                ) : (
                  <Autocomplete
                    id="area_of_work"
                    options={machineList}
                    getOptionLabel={(option) => option}
                    value={pirReportData?.mc_no || autocompletevalue}
                    onChange={(e, value) => {
                      pirReportData.mc_no = value;
                      setAutoCompleteValue(value);
                      // setAutoCompleteValue((prev) => {
                      //   console.log(prev);
                      //   prev[tabValue] = value;
                      //   return [...prev];
                      // });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                )}
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={4}
              >
                ITEM CODE:{" "}
                {pirReportData?.partNo || pirReportData.part_id.part_number}
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={4}
              >
                DESCRIPTION:{" "}
                {pirReportData?.partName || pirReportData.part_id.part_name}
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={4}
              >
                CUSTOMER NAME:{" "}
                <TableReadEditCell
                  name="customer"
                  type="text"
                  style={{ width: "100%" }}
                  value={pirReportData.customer}
                  isView={isView}
                  handleChange={(e) => {
                    pirReportData.customer = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={2}
              >
                P.O. NO.:{" "}
                <TableReadEditCell
                  name="po_no"
                  type="text"
                  style={{ width: "100%" }}
                  value={pirReportData.po_no}
                  isView={isView}
                  handleChange={(e) => {
                    pirReportData.po_no = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
            </tr>
            <tr>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={4}
              >
                DATE:
                {isView ? (
                  formatDate(pirReportData.pirReport_date)
                ) : (
                  <DatePicker
                    // label="pic Date"
                    name="date_performance"
                    format="dd/MM/yyyy"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{
                      backgroundColor: "#f8f9fa",
                    }}
                    value={pirReportData?.pirReport_date}
                    onChange={(date) => {
                      pirReportData.pirReport_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={4}
              >
                RM MRN/SFG PO NO:{" "}
                <TableReadEditCell
                  name="rm_mrm"
                  type="text"
                  style={{ width: "100%" }}
                  value={pirReportData.rm_mrm}
                  isView={isView}
                  handleChange={(e) => {
                    pirReportData.rm_mrm = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={4}
              >
                PROCESS STAGE:{" "}
                {isView ? (
                  pirReportData.process_stage
                ) : (
                  <Select
                    name="form_control"
                    style={{ width: "150px" }}
                    className="select-field"
                    variant="filled"
                    value={pirReportData.process_stage || ""}
                    onChange={(e) => {
                      pirReportData.process_stage = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem>None</MenuItem>
                    {processes.map((processNo) => (
                      <MenuItem value={processNo}>{processNo}</MenuItem>
                    ))}
                  </Select>
                )}
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={4}
              >
                SHIFT:
                {isView ? (
                  pirReportData.shift
                ) : (
                  <Select
                    name="form_control"
                    style={{ width: "150px" }}
                    className="select-field"
                    variant="filled"
                    value={pirReportData.shift || ""}
                    onChange={(e) => {
                      pirReportData.shift = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem>None</MenuItem>
                    <MenuItem value="Day">Day</MenuItem>
                    <MenuItem value="Night">Night</MenuItem>
                  </Select>
                )}
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="left"
                colSpan={2}
              >
                PRD. SUPERVISOR:{" "}
                <TableReadEditCell
                  name="prd"
                  type="text"
                  style={{ width: "100%" }}
                  value={pirReportData.prd}
                  isView={isView}
                  handleChange={(e) => {
                    pirReportData.prd = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
            </tr>

            {/*bottom seaction  */}

            <tr
              data-height="50"
              className="text-center"
              style={{
                background: "#e9ecef",
              }}
            >
              <th align="center" rowSpan={3}>
                SR NO.
              </th>
              <th align="center" rowSpan={3}>
                PARAMETER
              </th>
              <th align="center" rowSpan={3}>
                <p>SPECIFICATION</p>
              </th>
              <th align="center" rowSpan={3}>
                MEHTOD
              </th>
              <th align="center" colSpan={2}>
                FPA/SETUP
              </th>
              <th align="center" colSpan={8}>
                PETROLLING INSPECTION
              </th>
              <th align="center" colSpan={2}>
                LAST PART INSPECTION
              </th>
              <th align="center" colSpan={2}>
                SPECIAL CHECK
              </th>
            </tr>
            <tr
              className="text-center"
              style={{ height: "50px", background: "#e9ecef" }}
            >
              {[0, 1, 2, 3, 4, 5].map((timeIndex) => (
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                  colSpan={2}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      {readableTime(
                        pirReportData?.start_time?.[timeIndex] || "",
                      )}
                    </div>
                    <div>{readableTime(pirReportData.times[timeIndex])}</div>
                  </div>
                </th>
              ))}
              <th></th>
              <th></th>
            </tr>
            <tr data-height="30" className="text-center">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2].map(
                (heading, index) => (
                  <th
                    style={{
                      width: "60px",
                      border: "1px solid black",
                      textAlign: "center",
                      background: "#e9ecef",
                    }}
                    align="center"
                    key={index}
                  >
                    {heading}
                  </th>
                ),
              )}
            </tr>
          </thead>
          {pirReportData.pirs.length && (
            <tbody>
              {pirReportData.pirs.map((item, pirIndex) => (
                <tr className="text-center">
                  <td>{pirIndex + 1}</td>
                  <td
                    style={{
                      minWidth: isView ? "130px" : "inherit",
                    }}
                  >
                    {item.parameter}
                  </td>
                  <td>{item.specification}</td>
                  <td>{item.methods}</td>
                  {/* first 2 element -> FPA and Last 2 element -> simple check */}
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                    (obsvIndex) => (
                      <td
                        key={obsvIndex}
                        align="center"
                        style={{
                          background: isView
                            ? pirReportData.pirs[pirIndex][`check_0_colors`][
                                obsvIndex
                              ]
                            : "inherit",
                          minWidth: isView ? "80px" : "inherit",
                        }}
                      >
                        {/* checkboxtype, list pir index, inspection #1 ,2 ,3 ,4... */}
                        {handleObeservatonInputs(
                          item.appearance,
                          pirIndex,
                          obsvIndex,
                          "0",
                        )}
                      </td>
                    ),
                  )}
                </tr>
              ))}
            </tbody>
          )}
          <tr>
            <td colSpan={4}>NC RESULT</td>
            {Array(12)
              .fill(0)
              .map((_, index) => reusabelSelect(index))}
            <td colSpan={2}></td>
          </tr>
          <tr>
            <td colSpan={4}>REMARKS</td>
            {Array(6)
              .fill(0)
              .map((_, index) => (
                <th colSpan={2} align="left">
                  <TableReadEditCell
                    name="remarks"
                    type="text"
                    style={{ width: "100%" }}
                    value={pirReportData?.remarks?.[index]}
                    isView={isView}
                    handleChange={(e) => {
                      if (!pirReportData.remarks) pirReportData.remarks = [];
                      pirReportData.remarks[index] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </th>
              ))}
            <td colSpan={2}></td>
          </tr>
          <tr>
            <td colSpan={2}>Prepared By:</td>
            <td colSpan={7}>{pirReportData.report_prepared_by}</td>
            <td colSpan={2}>Approved By:</td>
            <td colSpan={7}>{pirReportData.report_approved_by}</td>
          </tr>
          <tr>
            <td colSpan={2}>Designation:</td>
            <td colSpan={7}>{pirReportData.prepared_by_designation}</td>
            <td colSpan={2}>Designation:</td>
            <td colSpan={7}>{pirReportData.approved_by_designation}</td>
          </tr>
        </Table>
      </TableScroll>
    </main>
  );
};

export default CreateEditInspectionReport;
