import React from "react";
import formatDate from "../../../components/Control/formatDate";
import Styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/GlobalContext";
import { images, textInputs } from "../../../constants/constants";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { useQuery } from "../../../components/Control/useQuery";
import useGetData from "./helper/useGetData";
import { mergerRowsFmea } from "../../AddEditDuplicatePart/quickView/partPlanQuickView/MergerRows.utils";
import { StyledTableHeaderRow } from "../../../golbalStyles/tableFreeze/RowFreeze.styled";
import { StyledTableHeaderTh1 } from "../../../golbalStyles/tableFreeze/ColumnFrezze.styled";
const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;

        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }
    .input {
        outline: none;
        border: none;
        height: 50px;
        text-align:center;
        padding:0;
    }
    .center-heading{
      text-align:center;
    }

`;

const FmeaTable = () => {
  const { state: states } = useAuth();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const uid = query.get("uid");
  const history = useHistory();
  const { moduleData, isLoading, error } = useGetData();
  mergerRowsFmea(moduleData);

  // const updateFmea = async () => {
  //   let isUpdate = window.confirm(
  //     "Are you sure you want to update this document?",
  //   );
  //   if (isUpdate) {
  //     let res = await axios
  //       .put(`/api/update/fmea/${fmeaId}`, moduleData)
  //       .catch((err) => console.log(err));
  //   }
  // };

  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          FAILURE MODE & EFFECT ANALYSIS
        </Typography>
      </div>
      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
        }}
      >
        <section style={{ overflowX: "auto", height: "100vh" }}>
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 5, 7, 18, 5, 18, 18, 5, 5, 15, 15, 15, 4, 4, 4, 4"
          >
            <thead>
              <tr>
                <th
                  colSpan="13"
                  rowSpan="5"
                  align="center"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    borderRight: "0px",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.CompanyLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  DOCUMENT NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  SLF FMEA 01
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  ISSUE DATE:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01/04/2019
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REVISION NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  04
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REVISION DATE:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01/07/2023
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  FMEA NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  SLF/FMEA/01
                </th>
              </tr>
              <tr>
                <th
                  colSpan={19}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  POTENTIAL FAILURE MODE AND EFFECTS ANALYSIS (PROCESS FMEA)
                </th>
              </tr>

              <tr>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  Company Name:
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  S.L Fasteners Pvt. Ltd.
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  Subject:
                </th>
                <th
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  PFMEA for Manufacturing of Blind Rivet
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  PFMEA Start Date:
                </th>
                <th
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  12.10.2019
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  PFMEA ID Number:
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  SLF/PFMEA/01
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  Plant Location:
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Kundli, Sonipat, Haryana
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  Cross Functional Team:
                </th>
                <th
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Saurabh Sood, Lalit Setia, Jatin, Manish, Rahul
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  PFMEA Revision Date:
                </th>
                <th
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  15.01.2025
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Process Responsibility:
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Lalit Setia & Manish
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  Customer Name:
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  For All
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  Model Year / Platform:
                </th>
                <th
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Not Applicable
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  PFMEA Latest Revision:
                </th>
                <th
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  37
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Confidentiality Level:
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Confidential
                </th>
              </tr>
            </thead>
            <tbody>
              <StyledTableHeaderRow
                top={"-1px"}
                style={{ fontWeight: "500" }}
                data-height="65"
              >
                <StyledTableHeaderTh1
                  left={0}
                  width="100px"
                  minWidth="100px"
                  align="center"
                  color="#fff"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  ITEM / FUNCTION
                </StyledTableHeaderTh1>
                <StyledTableHeaderTh1
                  left={"100px"}
                  width="100px"
                  minWidth="100px"
                  color="#fff"
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-f-sz="10"
                >
                  REQUIREMENT
                </StyledTableHeaderTh1>
                <StyledTableHeaderTh1
                  left={"200px"}
                  width="100px"
                  color="#fff"
                  minWidth="100px"
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "10rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL FAILURE MODE
                </StyledTableHeaderTh1>
                <StyledTableHeaderTh1
                  left={"300px"}
                  width="100px"
                  minWidth="100px"
                  color="#fff"
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL EFFECT(S) OF FAILURE
                </StyledTableHeaderTh1>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  SEV
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  CHT.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL CAUSE(S) OF FAILURE
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  OCC
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  CURRENT PROCESS CONTROLS (PREVENTION )
                </th>

                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  CURRENT PROCESS CONTROLS (DETECTION )
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  DET
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  RPN
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  RECOMMENDED ACTION(S)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  RESP. & TARGET COMPLETION DATE
                </th>
                <th
                  align="center"
                  colSpan={5}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  ACTION RESULTS
                </th>
              </StyledTableHeaderRow>
              <StyledTableHeaderRow top={"49px"}>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    textAlign: "center",
                  }}
                  data-f-sz="10"
                >
                  ACTIONS TAKEN
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  SEV
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  OCC
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  DET
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  RPN
                </th>
              </StyledTableHeaderRow>
              {moduleData?.processes?.map((process) =>
                process?.fmeas?.map((fail, failInd) => (
                  <tr data-height="65">
                    {failInd === 0 && (
                      <StyledTableHeaderTh1
                        left={0}
                        width="100px"
                        minWidth="100px"
                        align="center"
                        bgColor="#fff9db"
                        style={{ border: "1px solid black" }}
                        rowSpan={process.fmeas.length}
                        data-f-sz="10"
                      >
                        {process?.process_name}
                      </StyledTableHeaderTh1>
                    )}
                    {fail.requirement_include && (
                      <StyledTableHeaderTh1
                        left={"100px"}
                        width="100px"
                        minWidth="100px"
                        bgColor="#fff9db"
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.requirement_rowSpan}
                      >
                        {fail?.requirement}
                      </StyledTableHeaderTh1>
                    )}
                    {fail.potential_mode_include && (
                      <StyledTableHeaderTh1
                        left={"200px"}
                        width="100px"
                        minWidth="100px"
                        bgColor="#fff9db"
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.potential_mode_rowSpan}
                      >
                        {fail?.potential_mode}
                      </StyledTableHeaderTh1>
                    )}
                    {fail.eof_include && (
                      <StyledTableHeaderTh1
                        left={"300px"}
                        width="100px"
                        minWidth="100px"
                        bgColor="#fff9db"
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.eof_rowSpan}
                      >
                        {Array.isArray(fail?.eof) ? fail.eof[0] : fail.eof}
                      </StyledTableHeaderTh1>
                    )}

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.severity}
                    </td>

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                      height={fail?.failure_class ? "auto" : "30px"}
                    >
                      {fail?.failure_class}
                    </td>
                    {fail.potential_cause_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail?.potential_cause_rowSpan}
                      >
                        {Array.isArray(fail?.potential_cause)
                          ? fail.potential_cause[0]
                          : fail.potential_cause}
                      </td>
                    )}
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.occ}
                    </td>
                    {fail.control_prevention_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail?.control_prevention_rowSpan}
                      >
                        {fail?.control_prevention}
                      </td>
                    )}

                    {fail.control_detection_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.control_detection_rowSpan}
                      >
                        {fail?.control_detection}
                      </td>
                    )}

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.det}
                    </td>

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.det * fail?.occ * fail?.severity}
                    </td>
                    {fail.recommendation_action_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.recommendation_action_rowSpan}
                      >
                        {fail?.recommendation_action}
                      </td>
                    )}
                    <td align="center" data-f-sz="10">
                      {fail?.resp_target}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.action_taken}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_sev}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_occ}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_det}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_rpn}
                    </td>
                  </tr>
                )),
              )}
            </tbody>
            <tbody>
              <tr style={{ fontWeight: "bold" }}>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision No.
                </td>
                <td
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Date
                </td>
                <td
                  colSpan="6"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Reason For Revision
                </td>
                <td
                  colSpan="8"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Reason
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
              {moduleData?.revisionData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr data-height="150">
                      <td
                        colSpan="2"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.rev_no}
                      </td>
                      <td
                        colSpan="3"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {formatDate(item.rev_date)}
                      </td>
                      <td
                        colSpan="6"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.summary}
                      </td>
                      <td
                        colSpan="8"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.reason}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
            <tbody>
              <tr style={{ height: "60px" }} data-height="30">
                <td
                  colSpan="4"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                  data-f-sz="10"
                >
                  Prepared By:
                </td>
                <td
                  colSpan="5"
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                  data-f-sz="10"
                >
                  {states.user.name}
                </td>

                <td
                  colSpan="4"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                  data-f-sz="10"
                >
                  Approved By:
                </td>
                <td
                  colSpan="6"
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                ></td>
              </tr>
            </tbody>
          </Table>
        </section>
      </div>
    </>
  );
};

export default FmeaTable;
