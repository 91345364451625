import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { Search } from "@material-ui/icons";
import UpdateIcon from "@material-ui/icons/Update";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import CategoryIcon from "@material-ui/icons/Category";

import Input from "../../components/Control/Input";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import AddEditPart from "./AddEditPart";
import DuplicateTable from "./DuplicateTable";
import { Paper } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import useDebounce from "../../components/Control/useDebounce";
import { useAuth } from "../../context/GlobalContext";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import DescriptionIcon from "@material-ui/icons/Description";

import AddCategory from "./AddCategory/AddCategory";
import ConfirmDialogueForInput from "../../components/Control/ConfirmDialogueForInput";
import TextField from "@material-ui/core/TextField";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
import { usePermission } from "../../components/usePermission";
const statusObj = {
  draft: 1,
  publish: 2,
};

const PartList = () => {
  const [data, setData] = useState([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [filter, setFilter] = useState("part_number");
  const [dropDownRender, setDropDownRender] = useState(false);
  const [duplicateOpenModal, setDuplicateOpenModal] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const { state } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [partId, setPartId] = useState(null);
  // delete modal
  const [itemPartNumber, setItemPartNumber] = useState("");
  const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [categoryModal, setCategoryModal] = useState(false);
  //confirm modal
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE STATUS",
    "Are you sure you want to update this?",
  );
  // permission
  const permissions = usePermission(1);
  console.log(permissions);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/get/part/draft?page=${page}&sortBy=${filter}&search=${search}&filter=${true}`,
        {
          headers: { isFullAdmin: state?.user?.isFullAdmin },
        },
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    filter,
    duplicateOpenModal,
    isNewPartModal,
  ]);

  async function deletePart(id) {
    await axios.delete(`/api/delete/part/${id}`);
    setRender((prev) => !prev);
  }

  const handleChangeRequestStatus = (e, index) => {
    data[index].isSubmited = null;
    data[index].isAdminRequestNo = e.target.value;
    setDropDownRender((prev) => !prev);
  };

  const updateRequestForm = async (partId, index) => {
    let isConfirm = await confirmUpdate();
    if (isConfirm) {
      axios
        .patch(`/api/updateStatus/partroute/${partId}`, {
          isAdminRequestNo: data[index].isAdminRequestNo,
        })
        .then((res) => {
          if (res.data.status === "success") {
            alert("Status Change successfully");
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          ADD / PUBLISH PARTS
          {permissions?.includes("edit") && (
            <div>
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setCategoryModal(true)}
                style={{
                  background: "#76102a",
                  marginRight: "1rem",
                }}
                startIcon={<CategoryIcon />}
              >
                Add Category
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setDuplicateOpenModal(true)}
                style={{
                  background: "#003566",
                  marginRight: "1rem",
                }}
                startIcon={<FileCopyIcon />}
              >
                Duplicate Part
              </Button>

              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setPartId(null);
                  setIsNewPartModal(true);
                }}
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                  marginRight: "1rem",
                }}
                startIcon={<Add />}
              >
                New Part
              </Button>
            </div>
          )}
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Part Number"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="part_number">A - Z</MenuItem>
                <MenuItem value="createdAt">Most Recent First</MenuItem>
                <MenuItem value="createdAt_old">Oldest First</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item Number
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item Name/Description
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", minWidth: "160px" }}
                >
                  Control Plan Type
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Created At
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", width: "120px" }}
                >
                  Status
                </TableCell>

                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Documents List
                </TableCell>

                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        // width={20}
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {item.part_number}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          width: "20rem",
                          maxWidth: "20rem",
                        }}
                      >
                        {item.part_name}
                      </TableCell>
                      <TableCell align="center" style={{ width: "120px" }}>
                        {item.control_plan_type}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(item.createdAt)}
                      </TableCell>
                      <TableCell align="center">
                        {permissions?.includes("edit") ? (
                          <select
                            style={{
                              // height: "35px",
                              cursor: "pointer",
                              fontSize: "0.9rem",
                              outline: "none",
                              border: "none",
                              padding: "5px",
                              marginLeft: "0.5rem",
                              width: "10rem",
                              textAlign: "center",
                              textTransform: "capitalize",
                              ...(item.isSubmitted === "draft" && {
                                backgroundColor: "#FDD835",
                              }),
                              ...(item.isSubmitted === "publish" && {
                                backgroundColor: "#2b8a3e",
                                color: "#fff",
                              }),
                            }}
                            name="isSubmited"
                            value={
                              statusObj[item.isSubmited] ||
                              item.isAdminRequestNo
                            }
                            onChange={(e) =>
                              handleChangeRequestStatus(e, index)
                            }
                          >
                            <option value="1">DRAFT</option>
                            <option value="2">PUBLISH</option>
                          </select>
                        ) : (
                          <BlockIcon style={{ color: "#c92a2a" }} />
                        )}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "420px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "400px",
                            minWidth: "400px",
                            ...(!permissions?.includes("edit") && {
                              justifyContent: "center",
                            }),
                          }}
                        >
                          <div
                            style={{
                              marginRight: "8px",
                            }}
                          >
                            {permissions?.includes("edit") ? (
                              <Link
                                to={`/edit/pfd/${item._id}?partName=${item.part_name}&partNumber=${item.part_number}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    background: "#064a29",
                                    color: "#fafafa",
                                  }}
                                  startIcon={<SwapHorizontalCircleIcon />}
                                >
                                  Edit PFD
                                </Button>
                              </Link>
                            ) : (
                              <BlockIcon style={{ color: "#c92a2a" }} />
                            )}
                          </div>

                          <div
                            style={{
                              marginRight: "8px",
                            }}
                          >
                            {permissions?.includes("edit") ? (
                              <Link
                                to={`/edit/fmea/${item._id}?partName=${item.part_name}&partNumber=${item.part_number}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    background: "#931435",
                                    color: "#fafafa",
                                  }}
                                  startIcon={<SmsFailedIcon />}
                                >
                                  Edit FMEA
                                </Button>
                              </Link>
                            ) : (
                              <BlockIcon style={{ color: "#c92a2a" }} />
                            )}
                          </div>
                          <div>
                            {permissions?.includes("edit") ? (
                              <Link
                                to={`/edit/controlplan/${item._id}?partName=${item.part_name}&partNumber=${item.part_number}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    background: "#003566",
                                    color: "#fafafa",
                                  }}
                                  startIcon={<DescriptionIcon />}
                                >
                                  Edit CP
                                </Button>
                              </Link>
                            ) : (
                              <BlockIcon style={{ color: "#c92a2a" }} />
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "250px",
                            width: "220px",
                            alignItems: "center",
                          }}
                        >
                          {permissions?.includes("edit") && (
                            <Tooltip title="Edit Part" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: ".5rem",
                                }}
                                onClick={() => {
                                  setPartId(item._id);
                                  setIsNewPartModal(true);
                                }}
                              >
                                <EditIcon
                                  style={{ fontSize: "1.4rem", color: "black" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip title="Quick View" arrow>
                            <Link to={`/quick_view_documents/${item._id}`}>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.5rem",
                                }}
                              >
                                <VisibilityIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#003566",
                                  }}
                                />
                              </IconButton>
                            </Link>
                          </Tooltip>

                          {permissions?.includes("delete") && (
                            <Tooltip title="Delete Part" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.8rem",
                                }}
                                onClick={() => {
                                  setPartId(item._id);
                                  setItemPartNumber(item.part_number);
                                  setConfirmOpenForDelete(true);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#c80202",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          <div
                          // style={{
                          //   marginTop: "5px",
                          // }}
                          >
                            {permissions?.includes("edit") ? (
                              <Button
                                variant="outlined"
                                color="white"
                                style={{
                                  color: "#fafafa",
                                  backgroundColor: "#161A1D",
                                }}
                                onClick={(e) => {
                                  updateRequestForm(item._id, index);
                                }}
                                startIcon={
                                  <UpdateIcon style={{ fontSize: "1.4rem" }} />
                                }
                              >
                                Update
                              </Button>
                            ) : (
                              <BlockIcon style={{ color: "#c92a2a" }} />
                            )}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <DialogUpdate />

        <Modal
          title={partId ? "update new part" : "create new part"}
          openPopup={isNewPartModal}
          closeModal={() => {
            setIsNewPartModal(false);
            setPartId(null);
          }}
          backgroundColor="white"
        >
          <AddEditPart id={partId} {...{ setIsNewPartModal }} />
        </Modal>
        <Modal
          openPopup={duplicateOpenModal}
          title="DUplicate Data"
          closeModal={() => setDuplicateOpenModal(false)}
        >
          <DuplicateTable {...{ setDuplicateOpenModal }} />
        </Modal>
        <Modal
          openPopup={categoryModal}
          title="Add Category"
          closeModal={() => setCategoryModal(false)}
        >
          <AddCategory />
        </Modal>
        <ConfirmDialogueForInput
          title="DELETE PART"
          open={confirmOpenForDelete}
          setOpen={setConfirmOpenForDelete}
          onConfirm={(itemId) => deletePart(itemId)}
          isSubmitContent={false}
          itemPartName={itemPartNumber}
          itemId={partId}
          textFieldValue={textFieldValue}
          setTextFieldValue={setTextFieldValue}
        >
          <p style={{ margin: 0, fontSize: "1rem" }}>
            Are you sure you want to delete this part?
          </p>
          <p style={{ margin: 0, fontSize: "1rem" }}>
            Enter the <b>Part Number :</b> "{`${itemPartNumber}`}" to confirm
            part deletion:
          </p>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            autoComplete="false"
            label="Item Number"
            type="text"
            fullWidth
            onChange={(e) => setTextFieldValue(e.target.value)}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        </ConfirmDialogueForInput>
      </main>
    </>
  );
};

export default PartList;
