import React, { useEffect, useState } from "react";
import formatDate from "../../../components/Control/formatDate";
import Styled from "styled-components";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import { Wrapper } from "../../../components/Control/Flexbox";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/GlobalContext";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { StyledTableHeaderRow } from "../../../golbalStyles/tableFreeze/RowFreeze.styled";
import { StyledTableHeaderTh1 } from "../../../golbalStyles/tableFreeze/ColumnFrezze.styled";
const Table = Styled.table`
	margin-bottom: 1rem;
  overflow-x: 'auto';
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

`;

const mergerRowsFmea = (fmeaData) => {
  const fmeaParams = [
    { param: "requirement" },
    { param: "potential_mode" },
    { param: "severity" },
    { param: "failure_class" },
    { param: "potential_cause" },
    { param: "control_prevention" },
    { param: "control_detection" },
    { param: "recommendation_action" },
    { param: "det" },
    { param: "rec_action" },
    { param: "eof" },
  ];
  for (let [processIndex, process] of fmeaData.processes.entries()) {
    let prevRowValue = {};
    fmeaParams.forEach((item) => (prevRowValue[item.param] = null));
    let idxOfParmas = {};
    for (let [fmeaIndex, fmea] of process.fmeas.entries()) {
      fmeaParams.forEach((item, index) => {
        if (Array.isArray(fmea[item.param])) {
          fmea[item.param] = fmea[item.param][0];
        }
        if (
          prevRowValue[item.param] === fmea[item.param] &&
          prevRowValue[item.param] !== null &&
          prevRowValue[item.param] !== undefined
        ) {
          fmeaData.processes[processIndex].fmeas[idxOfParmas[item.param]][
            `${item.param}_rowSpan`
          ] += 1;
          fmea[`${item.param}_include`] = false;
        } else {
          idxOfParmas[item.param] = fmeaIndex;
          fmea[`${item.param}_rowSpan`] = 1;
          fmea[`${item.param}_include`] = true;
          prevRowValue[item.param] = fmea[item.param] || "";
        }
      });
    }
  }
};

const ViewFmea = () => {
  const [fmeaData, setFmeaData] = useState({});
  const { documentType, id } = useParams();
  const { state: states } = useAuth();
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getMasterById/${documentType}/${id}`)
        .then((res) => {
          if (res.data) {
            let data = res.data;
            mergerRowsFmea(data);
            setFmeaData(data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          FAILURE MODE & EFFECT ANALYSIS
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Export as spreadsheet">
            <Button
              style={{
                backgroundColor: "#161a1d",
                borderColor: "#161a1d",
                color: "white",
                width: "fit-content",
                borderRadius: "0",
                paddingTop: "9px",
                cursor: "pointer",
              }}
              onClick={() => {
                TableToExcel.convert(document.getElementById("table-to-xls"), {
                  name: "Failure Mode And Effect Analysis.xlsx",
                  sheet: {
                    name: "fmeaSheet",
                  },
                });
              }}
            >
              <Icon path={mdiFileExcel} size={1.3} />
            </Button>
          </Tooltip>
        </Wrapper>
      </div>
      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
        }}
      >
        <section style={{ overflowX: "auto", height: "100vh" }}>
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 5, 7, 18, 5, 18, 18, 5, 5, 15, 15, 15, 4, 4, 4, 4"
          >
            <thead>
              <tr>
                <th
                  colSpan="14"
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    borderRight: "0px",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.CompanyLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  DOCUMENT NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  SLF FMEA 01
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  ISSUE DATE:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01/04/2019
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REVISION NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  04
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REVISION DATE:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01/07/2023
                </th>
              </tr>
              <tr>
                <th
                  colSpan={19}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  POTENTIAL FAILURE MODE AND EFFECTS ANALYSIS (PROCESS FMEA)
                </th>
              </tr>
            </thead>
            <tbody>
              <StyledTableHeaderRow
                top={"-1px"}
                style={{ fontWeight: "500" }}
                data-height="65"
              >
                <StyledTableHeaderTh1
                  left={0}
                  width="100px"
                  minWidth="100px"
                  align="center"
                  color="#fff"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  ITEM / FUNCTION
                </StyledTableHeaderTh1>
                <StyledTableHeaderTh1
                  StyledTableHeaderTh1
                  left={"100px"}
                  width="100px"
                  minWidth="100px"
                  color="#fff"
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    // minWidth: "10rem",
                  }}
                  data-f-sz="10"
                >
                  REQUIREMENT
                </StyledTableHeaderTh1>
                <StyledTableHeaderTh1
                  left={"200px"}
                  width="100px"
                  color="#fff"
                  minWidth="100px"
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "10rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL FAILURE MODE
                </StyledTableHeaderTh1>
                <StyledTableHeaderTh1
                  left={"300px"}
                  width="100px"
                  minWidth="100px"
                  color="#fff"
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL EFFECT(S) OF FAILURE
                </StyledTableHeaderTh1>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  SEV.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  CHT.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL CAUSE(S) OF FAILURE
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  OCC.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  CURRENT PROCESS CONTROLS (PREVENTION )
                </th>

                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  CURRENT PROCESS CONTROLS (DETECTION )
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  DET.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  R.P.N.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  RECOMMENDED ACTION(S)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  RESP. & TARGET COMPLETION DATE
                </th>
                <th
                  align="center"
                  colSpan={5}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  ACTION RESULTS
                </th>
              </StyledTableHeaderRow>
              <StyledTableHeaderRow top={"49px"}>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    textAlign: "center",
                  }}
                  data-f-sz="10"
                >
                  ACTIONS TAKEN
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  SEV.
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  OCC.
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  DET.
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  R.P.N.
                </th>
              </StyledTableHeaderRow>
              {fmeaData.processes?.map((process) =>
                process?.fmeas?.map((fail, failInd) => (
                  <tr data-height="65">
                    {failInd === 0 && (
                      <StyledTableHeaderTh1
                        left={0}
                        width="110px"
                        minWidth="110px"
                        align="center"
                        bgColor="#fff9db"
                        style={{ border: "1px solid black" }}
                        rowSpan={process.fmeas.length}
                        data-f-sz="10"
                      >
                        {process?.process_name}
                      </StyledTableHeaderTh1>
                    )}
                    {fail.requirement_include && (
                      <StyledTableHeaderTh1
                        left={"110px"}
                        width="150px"
                        minWidth="150px"
                        bgColor="#fff9db"
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.requirement_rowSpan}
                      >
                        {fail?.requirement}
                      </StyledTableHeaderTh1>
                    )}
                    {fail.potential_mode_include && (
                      <StyledTableHeaderTh1
                        left={"260px"}
                        width="150px"
                        minWidth="150px"
                        bgColor="#fff9db"
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.potential_mode_rowSpan}
                      >
                        {fail?.potential_mode}
                      </StyledTableHeaderTh1>
                    )}
                    {fail.eof_include && (
                      <StyledTableHeaderTh1
                        left={"410px"}
                        width="150px"
                        minWidth="150px"
                        bgColor="#fff9db"
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.eof_rowSpan}
                      >
                        {Array.isArray(fail?.eof) ? fail.eof[0] : fail.eof}
                      </StyledTableHeaderTh1>
                    )}

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.severity}
                    </td>

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                      height={fail?.failure_class ? "auto" : "30px"}
                    >
                      {fail?.failure_class}
                    </td>
                    {fail.potential_cause_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail?.potential_cause_rowSpan}
                      >
                        {Array.isArray(fail?.potential_cause)
                          ? fail.potential_cause[0]
                          : fail.potential_cause}
                      </td>
                    )}
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.occ}
                    </td>
                    {fail.control_prevention_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail?.control_prevention_rowSpan}
                      >
                        {fail?.control_prevention}
                      </td>
                    )}

                    {fail.control_detection_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.control_detection_rowSpan}
                      >
                        {fail?.control_detection}
                      </td>
                    )}

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.det}
                    </td>

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {(+fail.severity || 0) *
                        (+fail.occ || 0) *
                        (+fail.det || 0)}{" "}
                    </td>

                    {/* verify with rahul */}
                    {fail.rec_action_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.rec_action_rowSpan}
                      >
                        {fail?.rec_action}
                      </td>
                    )}
                    <td align="center" data-f-sz="10">
                      {fail?.resp_target}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.action_taken}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_sev}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_occ}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_det}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {(+fail.last_sev || 0) *
                        (+fail.last_occ || 0) *
                        (+fail.last_det || 0)}
                    </td>
                  </tr>
                )),
              )}
            </tbody>
            <tbody>
              <tr style={{ fontWeight: "bold" }}>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Rev No.
                </td>
                <td
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Date
                </td>
                <td
                  colSpan="6"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Reason For Revision
                </td>
                <td
                  colSpan="8"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Reason
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
              {fmeaData?.revisionData &&
                fmeaData?.revisionData?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr data-height="150">
                        <td
                          colSpan="2"
                          style={{ border: "1px solid black" }}
                          align="center"
                          data-f-sz="11"
                        >
                          {item.rev_no}
                        </td>
                        <td
                          colSpan="3"
                          style={{ border: "1px solid black" }}
                          align="center"
                          data-f-sz="11"
                        >
                          {formatDate(item.rev_date)}
                        </td>
                        <td
                          colSpan="6"
                          style={{ border: "1px solid black" }}
                          align="center"
                          data-f-sz="11"
                        >
                          {item.summary}
                        </td>
                        <td
                          colSpan="8"
                          style={{ border: "1px solid black" }}
                          align="center"
                          data-f-sz="11"
                        >
                          {item.reason}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
            <tbody>
              <tr style={{ height: "60px" }} data-height="30">
                <td
                  colSpan="2"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                  data-f-sz="10"
                >
                  Prepared By:
                </td>
                <td
                  colSpan="3"
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                  data-f-sz="10"
                >
                  {states.user.name}
                </td>
                <td colSpan={6}></td>
                <td
                  colSpan="2"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                  data-f-sz="10"
                >
                  Approved By:
                </td>
                <td
                  colSpan="6"
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                ></td>
              </tr>
            </tbody>
          </Table>
        </section>
      </div>
    </>
  );
};

export default ViewFmea;
