import React, { useState } from "react";
import { images, textInputs } from "../../constants/constants";
import { MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import Styled from "styled-components";
import TableReadEditCell from "../../components/TableReadEditCell";
import formatDate from "../../components/Control/formatDate";
const Table = Styled.table`
	
    width:100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }
    .heading{
        th{
            text-align:center;
        }
        td{
            text-align:center;
        }
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        padding-left: 4px;
        height: 40px;
    }

`;
const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});
const CreateEditViewIncoming = ({ isView, data }) => {
  const classes = dropdownStyles();
  const [render, setRender] = useState(false);
  const handleChange = (e, index) => {
    const value = e.target.value;
    const name = e.target.name;
    if (index === 0 || index) {
      data.incomings[index][name] = value;
    } else {
      data[name] = value;
    }
    setRender((prev) => !prev);
  };

  function handleChangeSpecification(
    rangeValue,
    mean,
    utolerance,
    ltolerance,
    old_utl,
    old_ltl,
    check4_symbol,
    checkbox4_num,
  ) {
    const value = rangeValue;
    let utl;
    let ltl;
    let uv;
    let lv;
    //max
    if (check4_symbol && checkbox4_num) {
      if (check4_symbol === "<=") {
        if (value > checkbox4_num) {
          return "yellow";
        }
      }
      //min
      if (check4_symbol === ">=") {
        if (value < checkbox4_num) {
          return "yellow";
        }
      }
    }

    if (old_utl || old_ltl) {
      utl = parseFloat(old_utl);
      ltl = parseFloat(old_ltl);
      const mean = (utl + ltl) / 2;
      uv = (mean + utl) / 2;
      lv = (mean + ltl) / 2;
    } else {
      utl = parseFloat(mean) + parseFloat(utolerance);
      ltl = parseFloat(mean) - parseFloat(ltolerance);

      uv = parseFloat(mean) + parseFloat(utolerance) / 2;
      lv = parseFloat(mean) - parseFloat(ltolerance) / 2;
    }
    if (value >= utl || value < ltl) {
      return "yellow";
    }
  }
  return (
    <main style={{ margin: "1rem", paddingBottom: "3rem", overflow: "scroll" }}>
      <Table
        id="table-to-xls"
        data-default-wrap="true"
        data-cols-width="10, 20, 10, 15, 15, 16, 10, 10, 12, 16, 15 "
      >
        <tbody>
          <tr data-height="80">
            <th
              colSpan="9"
              align="left"
              rowSpan={4}
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
              }}
              data-a-v="middle"
            >
              <CenteredLogoImg>
                <img
                  src={images.CompanyLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th data-f-sz="10" colSpan={2}>
              FORMAT NO: F QAD 18
            </th>
          </tr>
          <tr>
            <th data-f-sz="10" colSpan={2}>
              ISSUE DATE: 01/04/2017
            </th>
          </tr>
          <tr>
            <th data-f-sz="10" colSpan={2}>
              REVISION NO: 04
            </th>
          </tr>
          <tr>
            <th data-f-sz="10" colSpan={2}>
              REVISION DATE: 09/07/2022
            </th>
          </tr>
          <tr>
            <th
              colSpan={11}
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                border: "1px solid black",
                textAlign: "center",
              }}
              data-font-sz="18"
              data-a-h="center"
              data-f-bold="true"
              data-fill-color="#1d1d1d"
              data-f-color="FFFFFF"
            >
              INCOMING MATERIAL INSPECTION REPORT
            </th>
          </tr>
          <tr>
            <td colSpan={3} data-f-sz="10">
              GRPO NO.:{" "}
              <TableReadEditCell
                type="text"
                name="mrm_no"
                value={data.mrm_no}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={3} data-f-sz="10">
              DATE:{" "}
              <TableReadEditCell
                type="text"
                name="in_date"
                value={data.in_date}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={5} data-f-sz="10">
              ITEM CODE: {data?.rm_id?.part_id?.item_no || data.item_code}
            </td>
          </tr>
          <tr>
            <td colSpan={6} data-f-sz="10">
              ITEM DESCRIPTION:{" "}
              {data?.rm_id?.part_id?.item_description || data.item_desc}
            </td>
            <td colSpan={5} data-f-sz="10">
              EXTERNAL PROVIDER: {data.ep}
            </td>
          </tr>
          <tr>
            <td colSpan={6} data-f-sz="10">
              LOT QUANTITY: {data.qty}
            </td>
            <td colSpan={5} data-f-sz="10">
              LOT CODE: {data.lot_code}
            </td>
          </tr>
          <tr>
            <td colSpan={6} data-f-sz="10">
              MATERIAL TYPE: {data.material_type}
            </td>
            <td colSpan={5} data-f-sz="10">
              INSPECTION DATE: {formatDate(data.inspection_date)}
            </td>
          </tr>

          <tr className="heading">
            {[
              "S.NO.",
              "CHARACTERISTICS",
              "UNIT",
              "METHOD",
              "SAMPLE TAKEN",
              "SPECIFICATION / TOLERANCE",
            ].map((heading) => (
              <th rowSpan={3}>{heading}</th>
            ))}
            <th data-f-sz="10" colSpan={3}>
              QUANTITY
            </th>
            <th data-f-sz="10">RESULTS</th>
            <th data-f-sz="10" rowSpan={3}>
              REMARKS
            </th>
          </tr>
          <tr className="heading">
            <th data-f-sz="10" colSpan={2}>
              WITH RANGE
            </th>
            <th data-f-sz="10" rowSpan={2}>
              BEYOND
            </th>
            <th data-f-sz="10" rowSpan={2}>
              (OK/UD/REJ.)
            </th>
          </tr>
          <tr className="heading">
            <th data-f-sz="10">MIN</th>
            <th data-f-sz="10">MAX</th>
          </tr>
          {data.incomings.map((incoming, index) => (
            <tr className="heading">
              <td data-f-sz="10">
                <TableReadEditCell
                  type="Number"
                  name="s_no"
                  value={incoming.s_no}
                  {...{ isView, handleChange, index }}
                />
              </td>
              <td data-f-sz="10">{incoming.characteristic}</td>
              <td data-f-sz="10">{incoming.unit}</td>
              <td data-f-sz="10">{incoming.method}</td>
              <td data-f-sz="10">{incoming.sample_taken}</td>
              <td data-f-sz="10">{incoming.control_product}</td>
              <td
                data-f-sz="10"
                style={{
                  background:
                    incoming.characteristic === "Diameter"
                      ? handleChangeSpecification(
                          data.obtained_size_min,
                          incoming.mean,
                          incoming.utolerance,
                          incoming.ltolerance,
                          incoming.utl,
                          incoming.ltl,
                          incoming.check4_symbol,
                          incoming.checkbox4_num,
                        )
                      : handleChangeSpecification(
                          data.ot_min,
                          incoming.mean,
                          incoming.utolerance,
                          incoming.ltolerance,
                          incoming.utl,
                          incoming.ltl,
                          incoming.check4_symbol,
                          incoming.checkbox4_num,
                        ),
                }}
              >
                <>
                  {incoming.characteristic === "Appearance" && (
                    <Select
                      name="form_control"
                      style={{ width: "100px" }}
                      className="select-field"
                      variant="filled"
                      defaultValue={incoming.check3_min || ""}
                      disableUnderline
                      onChange={(e) => {
                        incoming.check3_min = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    >
                      <MenuItem>None</MenuItem>
                      <MenuItem value="ok">OK</MenuItem>
                      <MenuItem value="not ok">NOT OK</MenuItem>
                    </Select>
                  )}
                  {incoming.characteristic === "Diameter" &&
                    data.obtained_size_min}
                  {(incoming.characteristic === "Tensile Strength" ||
                    incoming.characteristic === "Breakload") &&
                    data.ot_min}
                </>
              </td>
              <td
                data-f-sz="10"
                style={{
                  background:
                    incoming.characteristic === "Diameter"
                      ? handleChangeSpecification(
                          data.obtained_size_max,
                          incoming.mean,
                          incoming.utolerance,
                          incoming.ltolerance,
                          incoming.utl,
                          incoming.ltl,
                          incoming.check4_symbol,
                          incoming.checkbox4_num,
                        )
                      : handleChangeSpecification(
                          data.ot_max,
                          incoming.mean,
                          incoming.utolerance,
                          incoming.ltolerance,
                          incoming.utl,
                          incoming.ltl,
                          incoming.check4_symbol,
                          incoming.checkbox4_num,
                        ),
                }}
              >
                <>
                  {incoming.characteristic === "Appearance" && (
                    <Select
                      name="form_control"
                      style={{ width: "100px" }}
                      className="select-field"
                      variant="filled"
                      defaultValue={incoming.check3_max || ""}
                      onChange={(e) => {
                        incoming.check3_max = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    >
                      <MenuItem>None</MenuItem>
                      <MenuItem value="ok">OK</MenuItem>
                      <MenuItem value="not ok">NOT OK</MenuItem>
                    </Select>
                  )}
                  {incoming.characteristic === "Diameter" &&
                    data.obtained_size_max}
                  {(incoming.characteristic === "Tensile Strength" ||
                    incoming.characteristic === "Breakload") &&
                    data.ot_max}
                </>
              </td>
              <td data-f-sz="10" style={{ minWidth: "120px" }}>
                {(incoming.result === "UD" || incoming.result === "REJ") && (
                  <TableReadEditCell
                    type="text"
                    name="beyond"
                    style={{ width: "100px" }}
                    value={incoming.beyond}
                    {...{ isView, handleChange, index }}
                  />
                )}
              </td>
              <td data-f-sz="10">
                <Select
                  name="form_control"
                  variant="filled"
                  className="select-field"
                  style={{ width: "100px" }}
                  defaultValue={incoming.result || ""}
                  onChange={(e) => {
                    incoming.result = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem>None</MenuItem>
                  <MenuItem value="OK">OK</MenuItem>
                  <MenuItem value="UD">UD</MenuItem>
                  <MenuItem value="REJ">REJ</MenuItem>
                </Select>
              </td>
              <td data-f-sz="10">
                <TableReadEditCell
                  type="text"
                  name="remarks"
                  value={incoming.remarks}
                  {...{ isView, handleChange, index }}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={2} data-f-sz="10">
              DISPOSITION WAY: {data.disposition_way}
            </td>
            <td colSpan={3} data-f-sz="10">
              REJECTED: {data.rejected}
            </td>
            <td colSpan={4} data-f-sz="10">
              REWORK: {data.rework}
            </td>
            <td colSpan={2} data-f-sz="10">
              ACCEPTED UNDER DEVIATION: {data.deviation}
            </td>
          </tr>
          <tr>
            <td colSpan={2} data-f-sz="10">
              <TableReadEditCell
                type="text"
                name="disposition_1"
                value={data.disposition_1}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={3} data-f-sz="10">
              <TableReadEditCell
                type="text"
                name="rejected_1"
                value={data.rejected_1}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={4} data-f-sz="10">
              <TableReadEditCell
                type="text"
                name="rework_1"
                value={data.rework_1}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={2} data-f-sz="10">
              <TableReadEditCell
                type="text"
                name="deviation_1"
                value={data.deviation_1}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td data-f-sz="10" colSpan={11}>
              REMARKS: {data.remarks}
            </td>
          </tr>
          <tr>
            <td data-f-sz="10" colSpan={11}>
              Prepared By:
            </td>
          </tr>
          <tr>
            <td data-f-sz="10" colSpan={11}>
              Signature:
              <TableReadEditCell
                type="text"
                name="ic_prep_signature"
                value={data.ic_prep_signature}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td data-f-sz="10" colSpan={11}>
              Name:
              <TableReadEditCell
                type="text"
                name="ic_prep_name"
                value={data.ic_prep_name}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td data-f-sz="10" colSpan={11}>
              Designation:
              <TableReadEditCell
                type="text"
                name="ic_prep_designation"
                value={data.ic_prep_designation}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </main>
  );
};

export default CreateEditViewIncoming;
